<template>
    <!--滚动库存展示-->
    <div class="headdiv">
        <div style="width: 99.7%">
            <div id="alldiv2">
                <div style="width: 15%;border-right:3px solid #F00;height: 50px">
                    操作室
                </div>
                <div style="width: 15%;border-right:3px solid #F00">
                    操作人员
                </div>
                <div style="width: 35%;border-right:3px solid #F00">
                    操作内容
                </div>
                <div style="width: 35%;">
                    开始操作时间
                </div>
            </div>
        </div>
        <div>
            <vue3-seamless-scroll :list="list" class="scroll" :hoverStop=stopFalg step="0.3">
                <div class="item" v-for="(item, index) in list" :key="index">
                    <div id="alldiv">
                        <div style="width: 15%;border-right:3px solid #F00;height: 50px">
                            {{ item.roomName }}
                        </div>
                        <div style="width: 15%;border-right:3px solid #F00">
                            {{ item.userName }}
                        </div>
                        <div style="width: 35%;border-right:3px solid #F00">
                            {{ item.workMessage }}
                        </div>
                        <div style="width: 35%;" v-if="item.finishTime !== '-1'">
                            {{ item.workTime.split(' ')[1] }} - {{ item.finishTime.split(' ')[1] }}
                        </div>
                        <div style="width: 35%;" v-if="item.finishTime === '-1'">
                            {{ item.workTime.split(' ')[1] }} -
                        </div>
                    </div>
                </div>
            </vue3-seamless-scroll>
        </div>
    </div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import {Vue3SeamlessScroll} from "vue3-seamless-scroll";

export default {
    name: "sys_show_data",
    inject: ['reload'],
    computed: {
        ...mapGetters(['GET_BDST']),
        ...mapGetters(['GET_USER'])
    },
    components: {
        Vue3SeamlessScroll
    },
    setup() {
        // let route = useRouter();
        let list = JSON.parse(sessionStorage.getItem('sys_room_data'));
        return {list}
    },
    data() {
        return {
            stopFalg: true
        }
    },
    created() {
    },
    mounted() {
        var socket;
        if (typeof (WebSocket) == "undefined") {
            console.log("遗憾：您的浏览器不支持WebSocket");
        } else {
            console.log("恭喜：您的浏览器支持WebSocket");
            socket = new WebSocket("wss://www.cr12cz.cn/webSocket/233");
            socket.onopen = function() {
                socket.send("消息发送测试(From Client)");
            };
            //收到消息事件
            socket.onmessage = function(msg) {
                console.log(msg.data)
                if (msg.data === '1'){
                    window.open('https://www.cr12cz.cn/sys_show', '_self');
                }
            };
            socket.onclose = function() {
                //console.log("Socket已关闭");
            };
            //发生了错误事件
            socket.onerror = function() {
                //alert("Socket发生了错误");
            }

            //窗口关闭时，关闭连接
            window.unload=function() {
                socket.close();
            };
        }
    },
    methods: {}
}
</script>

<style>
.scroll {
    height: 1024px;
    overflow: hidden;
    width: 100%;
    margin: 0 auto 0px auto;
}

.scroll .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 0;
}

#alldiv {
    width: 100%;
    display: flex;
    border-top: 3px solid #F00;
    border-left: 3px solid #F00;
    border-right: 3px solid #F00;
    border-bottom: 3px solid #F00;
    color: red;
    text-align: center;
    line-height: 50px;
    font-size: 25px;
    font-weight: bolder;
}

#alldiv2 {
    width: 100%;
    display: flex;
    border-top: 3px solid #F00;
    border-left: 3px solid #F00;
    border-right: 3px solid #F00;
    border-bottom: 3px solid #F00;
    color: red;
    text-align: center;
    line-height: 50px;
    font-size: 25px;
    font-weight: bolder;
}

.headdiv {
    background-color: black;
    height: 30%;
    width: 100%;
    overflow: hidden
}
</style>